import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap-modal';

import { Loading } from 'components';
import { User, Profile, RidProfile } from 'api';
import { userPropTypes } from 'helpers/proptypes';
import { requestCurrentUser } from 'store/actions/accounts/user';

import ManagerSelection from './ManagerSelection';
import EmployeeAffiliation from './EmployeeAffiliation';
import CeuTracking from './CeuTracking';
import RidProfileForm from './RidProfile';
import SiteProfileForm from './SiteProfile';

class Onboarding extends React.Component {
  static propTypes = {
    user: userPropTypes,
    requestCurrentUser: PropTypes.func.isRequired,
  };

  state = {
    step: undefined,
    managerChoices: [],
    manager: null,
    isLoading: true,
    modal: {
      open: false,
      title: '',
      body: '',
    },
  };

  static getDerivedStateFromProps(props, state) {
    // If current state doesn't have a manager, but props has one available, use it.
    const managerProp = props.user && props.user.profile && props.user.profile.manager;
    if (!state.manager && managerProp) {
      return {
        // Form value is expected to be a string, but the API returns a number
        manager: '' + managerProp,
      };
    }
    return null;
  }

  closeModal = () => this.setState({ modal: { ...this.state.modal, open: false } });
  handleManagerChange = (manager) => this.setState({ manager });

  handleManagerSubmit = () => {
    const { manager } = this.state;
    if (manager) {
      Profile.patch(this.props.user.profile.pk, { manager }).then(() => {
        this.setState({ step: 1 });
        User.addPermission('can-register');
      });
    } else {
      this.setState({
        step: 1,
        modal: {
          ...this.state.modal,
          title: 'Notice',
          body: (
            <p>
              Without a manager you will not be able to register for Professional Development. You
              can assign your manager later through your profile.
            </p>
          ),
          open: true,
        },
      });
      User.removePermission('can-register');
    }
  };

  handleProfileSubmit = (values) => {
    const { step } = this.state;
    if (values && Object.keys(values).length !== 0) {
      Profile.patch(this.props.user.profile.pk, { ...values }).then(() =>
        this.setState({ step: step + 1 })
      );
    } else {
      this.setState({ step: step + 1 });
    }
  };

  handleRidProfileSubmit = (values) => {
    const { step } = this.state;
    if (values && Object.keys(values).length !== 0) {
      if (this.props.user.ridprofile && this.props.user.ridprofile.pk) {
        RidProfile.patch(this.props.user.ridprofile.pk, { ...values }).then(() =>
          this.setState({ step: step + 1 })
        );
      } else {
        RidProfile.post({ ...values, user: this.props.user.pk }).then(() =>
          this.setState({ step: step + 1 })
        );
      }
    } else {
      this.setState({ step: step + 1 });
    }
  };

  advanceToNextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  renderStep() {
    switch (this.state.step) {
      case 0:
        return (
          <ManagerSelection
            managerChoices={this.state.managerChoices}
            manager={this.state.manager}
            onSubmit={this.handleManagerSubmit}
            onChange={this.handleManagerChange}
          />
        );
      case 1:
        return (
          <EmployeeAffiliation
            onSubmit={this.handleProfileSubmit}
            values={{ employee_type: this.props.user.profile.employee_type }}
          />
        );
      case 2:
        return (
          <CeuTracking
            onSubmit={this.handleProfileSubmit}
            values={{
              ceu_tracking: `${this.props.user.profile.ceu_tracking}`,
            }}
          />
        );
      case 3:
        return (
          <RidProfileForm
            onSubmit={this.handleRidProfileSubmit}
            onSkip={this.advanceToNextStep}
            values={this.props.user.ridprofile}
          />
        );
      case 4:
        return (
          <SiteProfileForm
            onSubmit={this.handleProfileSubmit}
            onSkip={this.advanceToNextStep}
            values={this.props.user.siteprofile}
          />
        );
      case 5:
        return (
          <div>
            <h1 className="center article-title">Onboarding Complete!</h1>
            <p className="publication-title blog-post-meta">
              You may update this information at any time by visiting your{' '}
              <a href={window.URLS['accounts:settings']()}>account settings</a>.
            </p>
            <div className="center">
              <Link to={window.URLS['pd:home']()} className="btn btn-lg btn-primary">
                Home
              </Link>
            </div>
          </div>
        );
      default:
        return (
          <div>
            <h1 className="center article-title">Welcome!</h1>
            <p className="publication-title blog-post-meta">
              We have just a few questions to ensure everything is set up.
            </p>
            <div className="center">
              <button
                type="button"
                className="btn btn-lg btn-primary"
                onClick={() => this.setState({ step: 0 })}
              >
                Begin
              </button>
            </div>
          </div>
        );
    }
  }

  componentDidMount() {
    this.props.requestCurrentUser().then(() => {
      User.groupList('managers').then((res) => {
        const userIsManager = this.props.user.groups.find((group) => group.name === 'managers');
        const managers = userIsManager
          ? res.filter((manager) => !manager.groups.findIndex((group) => group.name === 'Dean'))
          : res;
        const managerChoices = managers.map((manager) => [manager.pk, manager.name]);
        this.setState({ managerChoices, isLoading: false });
      });
    });
  }

  render() {
    if (this.state.isLoading) return <Loading />;
    return (
      <div className="flex-single-row-container--loading" style={{ flexDirection: 'column' }}>
        {this.renderStep()}
        <Modal
          show={this.state.modal.open}
          onHide={this.closeModal}
          aria-labelledby="ModalHeader"
          modalPrefix="bootstrap-modal modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">{this.state.modal.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modal.body}</Modal.Body>
          <Modal.Footer>
            <Modal.Dismiss className="btn btn-info">OK</Modal.Dismiss>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ accountsUser }) => {
  return {
    user: accountsUser[accountsUser.current],
  };
};

export default connect(
  mapStateToProps,
  { requestCurrentUser }
)(Onboarding);

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-validify';

import { FormRadioGroup } from 'components';
import { EventDictionary, EventLabels } from 'helpers/dictionaries';

const CEU_TRACKING_CHOICES = Object.values(EventDictionary.CEU_TRACKING_CHOICES);
const ceuTrackingOptions = Object.entries(EventLabels.CEU_TRACKING_CHOICES);

const CeuTracking = (props) => {
  return (
    <div>
      <h2 className="center">CEU Tracking</h2>
      <Form
        values={props.values}
        rules={{
          ceu_tracking: `required|in:${CEU_TRACKING_CHOICES}`,
        }}
        attributeNames={{ ceu_tracking: 'CEU Tracking' }}
        className="mt2"
        style={{
          maxWidth: '400px',
        }}
      >
        <FormRadioGroup
          id="ceu_tracking"
          name="ceu_tracking"
          options={ceuTrackingOptions}
          label={{ htmlFor: 'ceu_tracking', text: 'CEU Tracking' }}
          help="If you wish to earn CEUs for events you attend, please select the program you will earn them through."
        />
        <div className="form-group mt2 center">
          <button
            type="button"
            submit
            className="btn btn-primary"
            onClick={(values) => props.onSubmit(values)}
          >
            Submit
          </button>
        </div>
      </Form>
    </div>
  );
};

CeuTracking.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,
};

export default CeuTracking;

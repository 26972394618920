import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-validify';
import { FormInput } from 'components';

const defaultValues = {
  first_name: '',
  last_name: '',
};

const SiteProfile = (props) => {
  return (
    <div>
      <h2 className="center">Site Profile Info</h2>
      <p>This is used only for your DAS PDP Profile and is separate from your RID info.</p>
      <Form
        values={props.values || defaultValues}
        rules={{
          first_name: 'string|required',
          last_name: 'string|required',
        }}
        attributeNames={{
          first_name: 'First name',
          last_name: 'Last name',
        }}
      >
        <FormInput
          id="first_name"
          name="first_name"
          type="text"
          className="form-control"
          label={{ htmlFor: 'first_name', text: 'First Name' }}
        />
        <FormInput
          id="last_name"
          name="last_name"
          type="text"
          className="form-control"
          label={{ htmlFor: 'last_name', text: 'Last Name' }}
        />
        <div className="form-group mt2 center">
          <button
            type="button"
            submit
            className="btn btn-primary"
            onClick={(values) => props.onSubmit(values)}
          >
            Submit
          </button>
        </div>
      </Form>
    </div>
  );
};

SiteProfile.defaultProps = {
  values: {
    first_name: '',
    last_name: '',
  },
};

SiteProfile.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,
};

export default SiteProfile;

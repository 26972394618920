import React from 'react';
import PropTypes from 'prop-types';
import Select, { Option } from 'rc-select';
import 'rc-select/assets/index.css';

const dropdownMenuStyle = {
  maxHeight: 200,
  overflow: 'auto',
};

const ManagerSelection = (props) => {
  const children = props.managerChoices.map((manager) => (
    <Option key={manager[0]} lowercaseName={manager[1].toLowerCase()}>
      {manager[1]}
    </Option>
  ));
  return (
    <div className="center">
      <h2 className="center">Manager Assignment</h2>
      <form className="mt2 mb1">
        <div className="form-group">
          <label htmlFor="" className="control-label">
            Who is your manager?
          </label>
          <div>
            <Select
              value={props.manager}
              dropdownMenuStyle={dropdownMenuStyle}
              style={{ width: 250 }}
              optionFilterProp="lowercaseName"
              optionLabelProp="children"
              placeholder="Select manager..."
              notFoundContent="No managers found"
              onChange={props.onChange}
            >
              {children}
            </Select>
          </div>
          <p className="help-block">
            If you do not have a manager or know your manager you may leave this blank.
          </p>
        </div>
        <div className="center">
          <button type="button" className="btn btn-primary mt2" onClick={props.onSubmit}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

ManagerSelection.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  manager: PropTypes.string,
  managerChoices: PropTypes.array,
};

export default ManagerSelection;

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-validify';

import { FormRadioBtnGroup } from 'components';
import { EventDictionary, EventLabels } from 'helpers/dictionaries';

const { EMPLOYEE_TYPES } = EventLabels;
const { INTERPRETER, CAPTIONIST, NON_RIT } = EventDictionary.EMPLOYEE_TYPES;

EMPLOYEE_TYPES[INTERPRETER] = 'DAS Interpeting';
EMPLOYEE_TYPES[CAPTIONIST] = 'DAS RTC-NT';
EMPLOYEE_TYPES[NON_RIT] = 'Other';
const employeeTypeOptions = Object.entries(EMPLOYEE_TYPES);

const EMPLOYEE_TYPE_CHOICES = Object.values(EventDictionary.EMPLOYEE_TYPES);

const EmployeeAffiliation = (props) => {
  return (
    <div className="center">
      <h2 className="center">Group Affiliation</h2>
      <Form
        values={props.values}
        rules={{ employee_type: `required|in:${EMPLOYEE_TYPE_CHOICES}` }}
        attributeNames={{ employee_type: 'group affiliation' }}
        className="mt2"
      >
        <FormRadioBtnGroup
          id="employee_type"
          name="employee_type"
          options={employeeTypeOptions}
          className="form-control"
          label={{ htmlFor: 'employee_type', text: 'Which group are you affiliated with?' }}
        />
        <div className="form-group mt2 center">
          <button
            type="button"
            submit
            className="btn btn-primary"
            onClick={(values) => props.onSubmit(values)}
          >
            Submit
          </button>
        </div>
      </Form>
    </div>
  );
};

EmployeeAffiliation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,
};

export default EmployeeAffiliation;
